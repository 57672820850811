
interface AnalyticsEvent {
  event_type: string;
  subject_type?: string;
  subject_id?: number;
  data?: object;
}


export interface LoggingEvent {
  event?: string;
  method?: string;
  result_id?: string | number;
  type?: string;
  internal_external?: string;
  from?: string;
  to?: string;
  location?: string;
  portfolio_id?: number;
  file_name?: string;
  file_extension?: string;
  file_text?: string;
  file_url?: string;
  resource_type?: string;
  view?: string;
  visible?: string;
  category?: string;
  criteria?: string;
  input?: string;
  url?: string;
  state?: string;
  contact_type?: string;
  contact_state?: string;
  contact_target?: string;
  agent?: string;
  owner?: string;
  place?: string;
  filter?: string;
  value?: string;
  option?: string;
  subject_type?: string;
}


export const transform_event = (logEvent: LoggingEvent): AnalyticsEvent => {

  const { type, event, result_id, subject_type, ...rest } = logEvent;
  delete rest['gtm.uniqueEventId'];

  const ret = { data: rest } as AnalyticsEvent


  if (event) {
    ret.event_type = event;
  }


  if (result_id) {
    ret.subject_id = Number(result_id) 
    ret.subject_type = type || '';
  }

  if(subject_type) {
    ret.subject_type = subject_type
  }

  return ret;

}

const NOT_ANALYTICS_EVENTS = ['page_view', 'misc', 'search_page_view']

/**
 * Log events to the GA environment
 * Also calls the js-api to log an analytics event for internal tracking
 */
export const logEvent = async (event) => {

  // If google analytics is active
  if(window.dataLayer) {
    dataLayer.push(event);
  }

  if (NOT_ANALYTICS_EVENTS.includes(event.event)) {
    // console.log('Skipping event', event.event)
    return;
  }

  try {
    // console.warn('Log event', event);

    // console.warn('Transformed', event_data)

    if (process.env.NEXT_PUBLIC_ANALYTICS_URL) {
      const event_data = transform_event(event); // { event_type: event.event, data: event }
      const res = await fetch(process.env.NEXT_PUBLIC_ANALYTICS_URL, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(event_data),
            credentials: 'include',
        })

        // if (res.ok) {
        //   console.warn('Logged event correctly');
        // }
    } else {
      // console.warn('No NEXT_PUBLIC_ANALYTICS_URL configured');
    }

  } catch (error) {
    console.warn('analytics event error', error);
  }

};

export default logEvent;
