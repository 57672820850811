import { createAsyncThunk, createAction, createSlice } from "@reduxjs/toolkit";
import { useSearchParams, useRouter } from 'next/navigation'
import { HYDRATE } from "next-redux-wrapper";
import nookies from "nookies";
import { useSelector } from "react-redux";

import { notificationAdd } from "./notifications";
import { setUserDetails, userRemove } from "./user";

import { statusIsGood } from "lib/middleware/errors";
import { AuthState } from "_interfaces/auth";

export const cookieUpdateState = createAction("cookieUpdateState");
export const setToken = createAction("setToken", function (payload) {
  return {
    payload: {
      ...payload,
      loggedIn: true,
    },
  };
});

export const setLoggedIn = createAction("setLoggedIn", function () {
  return {
    payload: {
      loggedIn: true,
    },
  };
})

const initialState: AuthState = {
  // General state of user
  loggedIn: false,
  // Session settings
  token: "",
  access_token: "",
  expires: "",
  expires_in: null,
  refresh_token: "",
  token_type: "",
  username: "",

  // Invite codes
  resetCode: "",
  inviteCode: "",

  // cookies
  //  1 The user has accepted cookies
  //  0 the user denied cookie use
  // -1 means page has loaded and the user is being asked to accept cookies
  // -2 means nothing has been set and no messge is showing
  cookieConsentGiven: -2,
};

const queryStringToObject = url =>
  [...new URLSearchParams(url.split('?')[1])].reduce(
    (a, [k, v]) => ((a[k] = v), a),
    {}
  );


export const setInviteCode = createAsyncThunk("setInviteCode", async (props, { getState }) => {

  let query = queryStringToObject(window.location.search);
  if (props.inviteCode === "" && query.inviteCode) {
    delete query.inviteCode;
    //delete query.resetCode;
    const newQuery = new URLSearchParams(query).toString()
    props.router.push(
      `${window.location.pathname}?${newQuery}`
    )
  }

  return props.inviteCode;
});

export const setResetCode = createAsyncThunk("setResetCode", async (props, { getState }) => {

  let query = queryStringToObject(window.location.search);
  if (props.resetCode === "" && query.resetCode) {
    delete query.resetCode;
    //delete query.resetCode;
    const newQuery = new URLSearchParams(query).toString()
    props.router.push(
      `${window.location.pathname}?${newQuery}`
    )
  }

  return props.resetCode;
});


export const userLogout = createAsyncThunk("userLogout", async (_notUsed, { dispatch }) => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_CR_JS_API}auth/logout-all`, {
    method: 'POST',
    headers: new Headers({ 'content-type': 'application/json' }),
    credentials: "include"
  })
  response.json().then(data => {
    if (statusIsGood(response.status)) {
      if (!data.loggedIn) {
        dispatch(userRemove());
        return data;
      }
    } else {
      dispatch(
        notificationAdd({
          location: "loginValidation",
          state: "error",
          messages: ["User credentials not recognised"],
        })
      );
    }
  })
});

export const refreshUserAuth = createAsyncThunk("refreshUserAuth", async (cookie, { dispatch }) => {
  let refresh_cookie = cookie?.refresh_token ? cookie.refresh_token : null;
  const { status, data } = await AuthApi.login({ refreshToken: refresh_cookie, rememberMe: cookie.rememberMe });

  if (statusIsGood(status)) {
    dispatch(setUserDetails(data.user));
    return data.auth;
  }
  return {};
});

export const resumeUserAuth = createAsyncThunk("resumeUserAuth", async (payload, { dispatch }) => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_CR_JS_API}auth/status`, {
    method: 'GET',
    headers: new Headers({ 'content-type': 'application/json' }),
    credentials: "include"
  })
  response.json().then(data => {
    if (statusIsGood(response.status) && data.user) {
      dispatch(setUserDetails(data.user));
      dispatch(setLoggedIn());
      return data;
    }
    return false;
  })
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(HYDRATE, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(setToken, (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      })
      .addCase(setLoggedIn, (state, action) => {
        return {
          ...state,
          ...action,
          loggedIn: true
        };
      })
      .addCase(refreshUserAuth.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          loggedIn: true,
        };
      })
      /* .addCase(resumeUserAuth.fulfilled, (state, action) => {
          return {
            ...state,
            ...action.payload,
            loggedIn: true,
          };
        })*/
      .addCase(cookieUpdateState, (state, action) => {
        nookies.set({}, "COOKIE_CONSENT_STATE", action.payload.toString(), {
          maxAge: 30 * 24 * 60 * 60,
          path: "/",
          sameSite: "None",
          secure: true,
        });
        state.cookieConsentGiven = action.payload;
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...initialState,
          cookieConsentGiven: state.cookieConsentGiven,
        };
      })
      .addCase(setInviteCode.fulfilled, (state, action) => {
        state.inviteCode = action.payload;
      })
      .addCase(setResetCode.fulfilled, (state, action) => {
        state.resetCode = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function

export const getAuthToken = (store) => store.auth.access_token;

export const isLoggedIn = (store) => store.auth.loggedIn;

export default authSlice.reducer;
